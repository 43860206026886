import React, { useEffect, useState } from "react";
import "@styles/index.scss";
import loadable from "@loadable/component";
import SEO from "../components/Seo";
import Navbar from "@organism/Navbar/Navbar";
import Footer from "@organism/Footer/Footer";

import ScrollTopButton from "@molecules/ScrollTopButton";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { isBrowser } from "@utils/isBrowser";
import FloatingSeeDemo from "@molecules/FloatingSeeDemo";
import PipeDriveChat from "@utils/pipedriveChat";
const Testimonies = loadable(() => import("@organism/Testimonies/Testimonies"));
const Methodology = loadable(() => import("../components/organism/Methodology"));
const TransformIdeas = loadable(() => import("@organism/TransformIdeas"));
const Monitor = loadable(() => import("@organism/Monitor"));
const ServiceTabs = loadable(() => import("@organism/ServiceTabs"));
const ServiceCards = loadable(() => import("@organism/ServiceCards"));
const HeaderAndVideo = loadable(() => import("@organism/HeaderAndVideo"));

const Services = ({ location }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const [chatStyle, setChatStyle] = useState(`textarea[class^="inputField_"]
  {
    color: #FFFFFF !important; 
     -webkit-text-fill-color: #FFFFFF !important;
  }`);
  const [clientChat, setClientChat] = useState(false);
  const [initChat, setinitChat] = useState(false);

  const handleOnLoad = () => {
    setTimeout(() => {
      setChatStyle(`textarea[class^="inputField_"]
      {
        color: #FFFFFF !important; 
        -webkit-text-fill-color: #FFFFFF !important;
      } `);
    }, 2500);
  };
  const openChat = () => {
    setinitChat(true);    
    setClientChat(t("JIVOCHAT"));
    handleOnLoad();
  };

  useEffect(() => {
    if (initChat) {      
      
      if (
        isBrowser &&
        clientChat !== false &&
        clientChat !== t("JIVOCHAT")
      ) {
        window.location.reload();
      }
    }
  }, []);
  return (
    <>
      {/* <CustomCursor /> */}
      <SEO 
        title={t('SEO.SERVICES.TITLE')} 
        description={t('SEO.SERVICES.DESCRIPTION')} 
        originUrl={location.origin}
        siteUrl={location.href}
        lang={language}
      />
      <Navbar location={location} />
      <HeaderAndVideo />
      <ServiceCards />
      <ServiceTabs />
      <Monitor />
      <TransformIdeas />
      <Methodology />
      <Testimonies location={location} />
      {/* <Chat
        chatStyle={chatStyle}
        clientChat={clientChat}
        initChat={initChat}
        openChat={openChat}
      /> */}
      <PipeDriveChat />
      <ScrollTopButton />
      <FloatingSeeDemo />
      <Footer openChat={openChat} />
    </>
  );
};

export default Services;
